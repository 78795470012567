<template>
  <div class="text-center engagement-td">
    <i v-if="showClick" class="fa fa-mouse-pointer e-click" v-b-tooltip.hover :title="clickLabel"
      aria-hidden="true"></i>
    <i v-if="showOpen" class="fa fa-envelope e-open" aria-hidden="true" v-b-tooltip.hover :title="openLabel"></i>
    <i v-if="showUnsubscribed" class="fa fa-envelope-o e-unsubscribe" aria-hidden="true" v-b-tooltip.hover
      :title="unsubscribedLabel"></i>
  </div>
</template>

<script>
export default {
  props: ['value', 'row', 'xprops'],
  computed: {
    showClick() {
      return this.row.email_clicked
    },
    showOpen() {
      return this.row.email_opened
    },
    showUnsubscribed() {
      return this.row.email_unsubscribed
    },
    clickLabel() {
      return `This contact has clicked the email on ` + this.$options.filters.date(this.row.email_clicked)
    },
    openLabel() {
      return `This contact has opened the email on ` + this.$options.filters.date(this.row.email_opened)
    },
    unsubscribedLabel() {
      return `This contact has unsubscribed the email on ` + this.$options.filters.date(this.row.email_unsubscribed)
    },
  }
}
</script>

<style lang="scss" scoped>
.engagement-td {
  font-size: 20px;

  i {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
  }

  .e-unsubscribe {
    color: #af1a33;
  }
}
</style>
