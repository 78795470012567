<template>
  <div class="broadcast-report">
    <vuestic-widget>
      <template slot="header">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <span class="title">Broadcast Report</span>
          <button type="button" class="btn btn-sm btn-primary" @click="onBack">
            <span>Back <i class="fa fa-arrow-left"></i></span>
          </button>
        </div>
      </template>
      <div class="row mt-4">
        <div class="col-md-12">
          <div class="filter-wrapper pb-2 mb-3">
            <div class="d-flex align-items-center">
              <AppDropdownSelect :options="bulkActionOptions" :autoHide=true :showArrow=false :selectable=false
                placement="bottom" popoverClass="more-custom-popover" @input="onBulkActionClick">
                <template slot="trigger">
                  <button href="javascript:;" class="btn btn-sm ml-2" :class="{
                    'btn-outline text-primary': !tableSelectedCount,
                    'btn-primary': tableSelectedCount
                  }">
                    <span>Bulk Actions</span>
                  </button>
                </template>
              </AppDropdownSelect>
              <span class="ml-2" v-if="tableSelectedCount"><b>{{ tableSelectedCount }}</b> Selected</span>
            </div>
            <div class="d-flex flex-row justify-content-end align-items-center right-filter-wrapper">
              <div class="d-flex align-items-center mr-2">
                <multiselect class="filter-select d-inline-block" style="width: 150px;" v-model="selectedStatus"
                  :options="statusList" :multiple="false" :close-on-select="true" :clear-on-select="false"
                  :preserve-search="true" placeholder="" label="label" track-by="value" :preselect-first="true"
                  :show-labels="false" :searchable="false" :allowEmpty="false" @input="onChangeFilter"></multiselect>
              </div>
              <div class="d-flex align-items-center justify-content-end flex-row mr-2">
                <search-input name="searchInput" v-model="searchFilter" @input="onChangeFilter" />
              </div>
              <div class="">
                <button class="btn btn-primary" @click="onResetFilter()">
                  <span>Reset</span>
                </button>
                <button class="btn btn-primary ml-2" @click="onClickDownload()">
                  <span>Download <i class="fa fa-download"></i></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="d-flex justify-content-center" v-if="isLoading">
            <span class="atom-spinner-wrapper">
              <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)" />
            </span>
          </div>
          <div v-else>
            <datatable v-bind="tableConfig" class="le-datatable" />
          </div>
        </div>
      </div>
    </vuestic-widget>
    <vuestic-modal :isOpen="isOpenModalSelectAllConfirmation" @ok="handleConfirmSelectAll"
      @cancel="isOpenModalSelectAllConfirmation = false" okText="Confirm" cancelText="Cancel" okClass="btn btn-primary">
      <span slot="title" class="text-primary font-weight-bold">Confirm Select All?</span>

      <div>You are going to selected <b>{{ tableTotal }}</b> contacts, Please confirm you want to select all contacts
      </div>
    </vuestic-modal>
    <vuestic-modal :isOpen="isOpenModalOptout" @ok="handleConfirmOptout" @cancel="isOpenModalOptout = false"
      okText="Optout" cancelText="Cancel" :closeOnOk="false" okClass="btn btn-danger">
      <span slot="title" class="text-danger font-weight-bold">Optout Contacts</span>

      <div>Confirm you want to optout <b>{{ tableSelectedCount }}</b> selected contacts?</div>
    </vuestic-modal>
    <vuestic-modal :isOpen="isOpenModalDownload" @ok="handleConfirmDownload" @cancel="isOpenModalDownload = false"
      okText="Download" cancelText="Cancel" :closeOnOk="false" :processing="processing" okClass="btn btn-primary">
      <span slot="title" class="text-primary font-weight-bold">Download Report</span>

      <div>Are you sure you want to download the broadcast report?</div>
    </vuestic-modal>
  </div>
</template>
<script>
import ReportAction from './Components/ReportAction'
import TdEngagement from './Components/TdEngagement'
import SearchInput from '../../common/SearchInput'
import AppDropdownSelect from '../../common/app-dropdown-select/AppDropdownSelect';
import components from "../../common/tables/comps";

export default {
  components: {
    SearchInput, AppDropdownSelect, TdEngagement,
  },
  data() {
    return {
      bulkActionOptions: [
        { icon: 'fa fa-check', label: 'Select All', value: 'selectAll' },
        { icon: 'fa fa fa-sign-out text-danger', label: 'Opt Out', value: 'optout' },
      ],
      isLoading: false,
      processing: false,
      tableConfig: {
        supportBackup: false,
        supportNested: false,
        HeaderSettings: false,
        tblClass: "table-bordered",
        tblStyle: "color: #666",
        pageSizeOptions: [10, 25, 50, 100],
        columns: [
        ],
        data: [],
        selection: [],
        total: 0,
        summary: {},
        query: {
        },
        xprops: {
          eventbus: new Vue()
        }
      },
      searchFilter: null,
      broadcast: null,
      isBulkOperation: false,
      isSelectAll: false,
      tableSelectedCount: 0,
      isOpenModalSelectAllConfirmation: false,
      isOpenModalOptout: false,
      statusList: [],
      selectedStatus: null,
      isOpenModalDownload: false,
    }
  },

  computed: {
    provider() {
      return this.broadcast && this.broadcast.provider
    },
    isEmail() {
      return this.provider === 'email'
    },
    tableTotal() {
      return this.tableConfig.total
    },
  },

  watch: {
    'tableConfig.query': {
      handler() {
        this.loadData()
      },
      deep: true
    },
    'tableConfig.selection': {
      handler: function (newValue, oldValue) {
        if (this.isSelectAll) {
          this.tableSelectedCount = this.tableTotal
          this.isSelectAll = false;
        } else {
          this.tableSelectedCount = newValue.length
        }
      },
      deep: true,
    }
  },

  created() {
    this.tableConfig.xprops.eventbus.$on("onToggleOptOut", (row) => {
      this.toggleOptOut(row)
    });
  },

  mounted() {
    this.statusList = [
      { value: '', label: 'All' },
    ];
    this.getBroadcast()
  },

  methods: {
    onBack() {
      this.$router.push({ name: 'business.broadcast.index', query: { type: this.provider } })
    },

    onClickDownload() {
      this.isOpenModalDownload = true
    },

    onBulkActionClick(item) {
      if (item.value === 'selectAll') {
        if (this.tableTotal) {
          this.isOpenModalSelectAllConfirmation = true
        }
        return false;
      }

      if (!this.tableConfig.selection.length)
        return false

      this.isBulkOperation = true;

      switch (item.value) {
        case 'optout':
          this.onOptoutClick()
          break;
        default:
          break;
      }
    },

    handleConfirmDownload() {
      this.processing = true
      const { query } = this.tableConfig;
      const param = {
        order: query.order,
        sort: query.sort,
        id: this.broadcast.id,
        search: this.searchFilter,
        status: this.selectedStatus ? this.selectedStatus.value: '',
      }
      this.$store.dispatch('broadcast/downloadReport', param).then((res) => {
        this.processing = false
        const blob = new Blob([res], { type: 'application/xlsx' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'broadcast-report.xlsx'
        link.click()
        URL.revokeObjectURL(link.href)
        this.isOpenModalDownload = false
      }).catch(e => {
        this.processing = false
      })
    },

    handleConfirmSelectAll() {
      this.isSelectAll = true;
      this.tableConfig.selection = [...this.tableConfig.data]
      this.isOpenModalSelectAllConfirmation = false
    },

    onOptoutClick() {
      this.isOpenModalOptout = true;
    },

    handleConfirmOptout() {
      let ids = [];
      let type = 'single';
      let filter = {}
      if (this.isBulkOperation) {
        ids = this.tableConfig.selection.map(item => item.contact_id);
        type = 'bulk';
        if (this.tableSelectedCount == this.tableTotal) {
          type = 'all';
          filter = {
            status: this.selectedStatus ? this.selectedStatus.value: '',
            broadcastId: this.broadcast.id,
            search: this.searchFilter,
          }
        }
      } else {
      }

      const payload = {
        ids: ids,
        type: type,
        filter,
      }

      this.$store.dispatch('contact/bulkOptout', payload).then(() => {
        this.isOpenModalOptout = false;
        this.isBulkOperation = false;
        this.loadData();
      })
    },

    getBroadcast() {
      const id = this.$route.params.id;
      this.$store
        .dispatch("broadcast/get", id)
        .then((res) => {
          this.broadcast = res
          if (this.isEmail) {
            this.tableConfig.columns = [
              { title: "Email", field: "email", label: "Email", tdClass: "center", sortable: true, visible: true },
              { title: "Status", field: "status", label: "Status", tdClass: "center", sortable: true, visible: true },
              { title: "Engagement", field: "status", label: "Engagement", tdClass: "center", tdComp: TdEngagement, sortable: true, visible: true },
              { title: 'ProcessedAt', field: 'created_at', sortable: true, tdComp: 'TdTimestamp', tdClass: "center", },
              { title: "Error Message", field: "error_message", label: "Error Message", tdComp: "TdVhtml", sortable: false, },
              { title: 'Opt Out', field: 'opt_out', sortable: true, tdComp: 'TdTimestamp', tdClass: "center", },
              { title: '', tdComp: ReportAction, visible: 'true', tdClass: 'center', },
            ]
          } else {
            this.tableConfig.columns = [
              { title: "Number", field: "number", label: "Number", tdClass: "center", tdComp: "TdPhone", sortable: true, visible: true },
              { title: "Status", field: "status", label: "Status", tdClass: "center", sortable: true, visible: true },
              { title: "ErrorCode", field: "error_code", label: "ErrorCode", tdClass: "center", sortable: true, },
              { title: "Error Message", field: "error_message", label: "Error Message", tdComp: "TdVhtml", sortable: false, },
              { title: 'ProcessedAt', field: 'created_at', sortable: true, tdComp: 'TdTimestamp', tdClass: "center", },
              { title: 'Opt Out', field: 'opt_out', sortable: true, tdComp: 'TdTimestamp', tdClass: "center", },
              { title: 'Link Opened', field: 'link_opened', tdComp: 'TdBoolean', sortable: false, },
              { title: '', tdComp: ReportAction, visible: 'true', tdClass: 'center', },
            ]
          }
          this.initData()
        })
        .catch((err) => {
          this.redirectOnError();
        });
    },

    redirectOnError() {
      this.$router.push({ name: "business.broadcast.index" });
    },

    onChangeFilter() {
      this.tableConfig.query.offset = 0
      this.loadData()
    },

    onResetFilter() {
      this.searchFilter = ''
      this.onRefresh()
    },

    onRefresh() {
      this.loadData();
    },

    initData() {
      if (!this.isEmail) {
      this.statusList = this.statusList.concat([
          { value: 'sent', label: 'Sent' },
          { value: 'delivered', label: 'Delivered' },
          { value: 'failed', label: 'Failed' },
        ]);
      } else {
        this.statusList = this.statusList.concat([
          { value: 'sent', label: 'Delivered' },
          { value: 'failed', label: 'Failed' },
          { value: 'spamreport', label: 'Spam' },
        ]);
      }

      this.selectedStatus = this.statusList[0]
      this.loadData()
    },

    loadData() {
      this.tableConfig.selection = []
      this.isLoading = true
      const { query } = this.tableConfig;
      const page = query.offset
        ? Math.floor(query.offset / query.limit) + 1
        : 1;
      const param = {
        page,
        per_page: query.limit,
        order: query.order,
        sort: query.sort,
        id: this.$route.params.id,
        search: this.searchFilter,
        status: this.selectedStatus ? this.selectedStatus.value: '',
      };

      this.$store.dispatch('broadcast/report', param)
        .then((data) => {
          this.tableConfig.data = data.data
          this.tableConfig.total = data.meta.total
          this.isLoading = false
        })
        .catch((e) => {
          this.isLoading = false
        })
    },

    toggleOptOut(row) {
      this.$store
        .dispatch('contact/toggleOptOut', row.contact_id)
        .then(() => {
          this.loadData();
        })
        .catch(() => {
        })
    },
  },
}
</script>
<style lang="scss">
.broadcast-report {
  .filter-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
